import '/assets/styles/sections/home-mainslider.scss';

import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints'

// Partials
import Btn from '/views/partials/btn'
import Placeholder from '/views/partials/placeholder'
import Slider from '/views/partials/slider'
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';

// Static Assets
const autoPlayDelay = 5000;

const MainSlider = ({ data }) => {
	const [activeCategory, setActiveCategory] = useState(0);
	const [categories, setCategories] = useState(data);
	
	const autoplayComplete = () => {
		if(categories.length > 1) {
			setActiveCategory(categories.length === activeCategory + 1 ? 0 : activeCategory + 1);
		}
	}
	
	useEffect(() => {
		setActiveCategory(0);
		setCategories(data);
	}, [data])

	return (
		<section className="section home-mainslider">
			<div className="mainslider-nav">
				<div className="nav-wrap wrapper">
					{categories ?
						<>
							{categories.map((category, nth) => (
								<button
									onClick={() => { setActiveCategory(nth); }}
									className={'nav-item' + (activeCategory === nth ? ' active' : '')}
									key={`home-mainslider-nav-${category.key}-${nth}`}>
									{category.title}
								</button>
							))}
						</>
						:
						<>
							<Placeholder className="nav-item" />
							<Placeholder className="nav-item" />
							<Placeholder className="nav-item" />
							<Placeholder className="nav-item" />
						</>
					}
				</div>
			</div>
			{categories ?
				<>
					{categories.map((category, nth) => {
						if(activeCategory === nth) {
							return (
								<SliderElem
									index={nth}
									key={`sliderelem-${category.key}-${nth}`}
									slides={categories[activeCategory].slides}
									categoryTitle={categories[activeCategory].title}
									onAutoplayComplete={autoplayComplete} />
							)
						}
						else {
							return false;
						}
					})}
				</>
				:
				<SliderElem
					index={0}
					key="sliderelem-placeholder"
					slides={false} />
			}
		</section>
	)
}

MainSlider.propTypes = {
	data: PropTypes.array,
}

const SliderElem = ({ slides, index, onAutoplayComplete, categoryTitle }) => {
	const _mounted = useRef(false);
	const mainSliderRef = useRef(null);
	const thumbSliderRef = useRef(null);
	const switchTimer = useRef(false);
	const { mobile } = useBreakpoints();

	const [activeSlide, setActiveSlide] = useState(0);

	const slideChange = () => {
		if(mainSliderRef.current) {
			try {
				const index = mainSliderRef.current.realIndex;
				setActiveSlide(index);
				if(thumbSliderRef.current) {
					thumbSliderRef.current.slideTo(index);
				}

				// Autoplay Check
				if(index + 1 === slides.length) {
					if(mainSliderRef.current?.autoplay) {
						mainSliderRef.current.autoplay.stop();
					}
					switchTimer.current = setTimeout(() => {
						if(_mounted.current) {
							switchTimer.current = false;
							onAutoplayComplete();
						}
					}, autoPlayDelay)
				}
				else if(switchTimer.current) {
					clearTimeout(switchTimer.current);
					switchTimer.current = false;
					if(mainSliderRef.current?.autoplay) {
						mainSliderRef.current.autoplay.start();
					}
				}
			}
			catch(e) {
				console.error('Slide change error:', e)
			}
		}
	}

	const selectSlide = (index) => {
		if(_mounted.current) {
			try {	
				mainSliderRef.current.slideToLoop(index);
			}
			catch(e) {
				console.error('Main slider error:', e);
			}
		}
	}

	useEffect(() => {
		_mounted.current = true;

		if(slides.length === 1) {
			switchTimer.current = setTimeout(() => {
				if(_mounted.current) {
					switchTimer.current = false;
					onAutoplayComplete();
				}
			}, autoPlayDelay)
		}

		return () => {
			_mounted.current = false;

			if(switchTimer.current) {
				clearTimeout(switchTimer.current);
			}
		}
	}, [slides, onAutoplayComplete])

	return (
		<div className="mainslider-sliderwrap">
			{slides && slides.length > 0 &&
				<>
					<Slider
						key={`home-main-mainslider-${index}`}
						className="sliderwrap-slider"
						params={{
							loop: true,
							loopAdditionalSlides: mobile ? 1 : 0,
							// autoplay: {
							// 	delay: autoPlayDelay,
							// 	disableOnInteraction: false,
							// 	pauseOnMouseEnter: true,
							// },
							onSwiper: (instance) => {
								mainSliderRef.current = instance;
							},
							onBeforeDestroy: () => {
								mainSliderRef.current = false;
							},
							onSlideChange: slideChange,
						}}>
						{slides.map((slide, nth) => {
							const isLinkWrap = true;
							const WrapElem = isLinkWrap ? Link : 'div';
							return (
								<WrapElem
									raw={isLinkWrap ? true : undefined}
									href={isLinkWrap ? slide.link : undefined}
									target={isLinkWrap ? slide.target : undefined}
									rel={isLinkWrap ? 'noopener noreferrer' : undefined}
									className={`mainslider-slide${slide.title ? ' has-title' : ' image-only'}`}
									key={nth}>
									{/* {slide.id === 53 ?
										<SlideVideo slide={slide} active={activeSlide === nth} />
										:
										<Img
											bg lazy
											className="slide-image"
											src={slide.image_url} />
									} */}
									<Img
										key={`slide-image-${slide.image_url ? slide.image_url : 'dummy'}`}
										loading={nth === 0 ? "eager" : undefined}
										lazy={nth === 0 ? false : undefined}
										cover
										className="slide-image"
										alt={slide.title ? slide.title : `${categoryTitle}: Slayt ${nth+1}`}
										src={slide.image_url} />
									{slide.title &&
										<div className="slide-innerwrap wrapper">
											<div className="slide-textcontent">
												{slide.sub_title &&
													<span className={`textcontent-label label orange${!mobile ? ' big' : ''}`}>{slide.sub_title}</span>
												}
												<strong className="textcontent-title">{slide.title}</strong>
												{!mobile && slide.link &&
													<Btn
														// raw
														// tag="link"
														// href={slide.link}
														className={`textcontent-cta white wide bold outline${!mobile ? ' big' : ''}`}>
														{(slide.action_text && slide.action_text.length > 0) ? slide.action_text : 'İncele'}
													</Btn>
												}
											</div>
										</div>
									}
								</WrapElem>
							)
						})}
					</Slider>
					{!mobile &&
						<>
							{slides.length > 7 ?
								<Slider
									key={`home-main-mainslider-thumbs-${index}`}
									className="sliderwrap-thumbs"
									params={{
										centeredSlides: true,
										centeredSlidesBounds: true,
										slidesPerView: 7,
										touchRatio: 0.2,
										watchOverflow: true,
										navigation: true,
										loop: false,
										onSwiper: (instance) => {
											thumbSliderRef.current = instance;
										},
										onBeforeDestroy: () => {
											thumbSliderRef.current = null;
										},
									}}>
									{slides.map((slide, nth) => (
										<div className="thumbs-thumb lolol" key={`thumb-${nth}-${slide.id}`}>
											<button className={'thumb-btn' + (activeSlide === nth ? ' active' : '')} onClick={() => { selectSlide(nth); }}>
												<div className="thumb-imagewrap" style={{ backgroundImage: `url(${slide.image_thumb_url})` }}></div>
											</button>
										</div>
									))}
								</Slider>
							:
								<div className="sliderwrap-thumbs static">
									{slides.map((slide, nth) => (
										<div className="thumbs-thumb" key={`thumb-mobile-${nth}-${slide.id}`}>
											<button className={'thumb-btn' + (activeSlide === nth ? ' active' : '')} onClick={() => { selectSlide(nth); }}>
												<div className="thumb-imagewrap" style={{ backgroundImage: `url(${slide.image_thumb_url})` }}></div>
											</button>
										</div>
									))}
								</div>
							}
						</>
					}
				</>
			}
			{!slides &&
				<>
				<Placeholder className="sliderwrap-slider" />
					{!mobile &&
						<div className="sliderwrap-thumbs static placeholder">
							<div className="thumbs-thumb">
								<Placeholder className="thumb-btn" />
							</div>
							<div className="thumbs-thumb">
								<Placeholder className="thumb-btn" />
							</div>
							<div className="thumbs-thumb">
								<Placeholder className="thumb-btn" />
							</div>
							<div className="thumbs-thumb">
								<Placeholder className="thumb-btn" />
							</div>
						</div>
					}
				</>
			}
		</div>
	)
}

SliderElem.propTypes = {
	slides: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([false])]),
	index: PropTypes.number,
	categoryTitle: PropTypes.string,
	onAutoplayComplete: PropTypes.func,
}

// const SlideVideo = ({ slide, active }) => {
// 	const videoElem = useRef();

// 	useEffect(() => {
// 		if(active){
// 			if(videoElem.current && videoElem.current.paused) {
// 				videoElem.current.currentTime = 0;
// 				videoElem.current.play();
// 			}
// 		}
// 		else {
// 			if(videoElem.current) {
// 				videoElem.current.pause();
// 				videoElem.current.currentTime = 0;
// 			}
// 		}
// 	}, [active])

// 	return (
// 		<div className="slide-image video">
// 			<video
// 				muted
// 				ref={videoElem}
// 				className="image-video"
// 				src="/dummy/videos/mainslider-video.mp4" />
// 		</div>
// 	)
// }

export default MainSlider